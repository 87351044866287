<template>
  <AView
    :value="value"
    :active="caseStudy.id === activeCaseStudyId"
    :name="caseStudy.name"
    ref="caseStudy-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @action="(action) => action.handler({ from: value, caseStudy })"
  >
    <template v-slot:content="{}">
      <v-card class="transparent mt-4 pa-2 elevation-0">
        <v-card-text>
          <CaseStudyEditorVue
            ref="form"
            v-model="caseStudy"
            :permissions="value.permissions"
            @onLinkCreate="onLinkCreate"
            @onLinkRemove="onLinkRemove"
            @action="(action) => action.handler({ from: value, caseStudy })"
          ></CaseStudyEditorVue>
        </v-card-text>
      </v-card>
    </template>
  </AView>
</template>
    
    
<script>
import { mapGetters, mapState } from "vuex";
import CaseStudyEditorVue from "../../../../../../components/wad/organisms/knowledgeBase/caseStudies/forms/editor/CaseStudyEditor.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    CaseStudyEditorVue,
  },
  data() {
    return {
      updatedTimeout: undefined,

      caseStudy: {
        searchableKeyword: {},
      },
    };
  },
  computed: {
    ...mapState("CaseStudyStore", [
      "activeCaseStudyId",
      "displayedCaseStudies",
    ]),
    ...mapGetters("CaseStudyStore", ["caseStudyById"]),
  },
  created() {
    this.$store.dispatch("CaseStudyStore/GetCaseStudy", {
      id: this.value.relation.params.caseStudyId,
    });

    this.caseStudy = this.caseStudyById(this.value.relation.params.caseStudyId);
  },
  methods: {
    onHover() {
      this.$store.commit(
        "CaseStudyStore/setActiveId",
        this.value.relation.params.caseStudyId
      );
    },
    async onLinkCreate(link) {
      const newLink = await this.$store.dispatch(
        "KnowledgeBaseStore/CreateLink",
        {
          link: { link },
        }
      );

      console.log("newLink: ", newLink);

      this.caseStudy.links.push({ link: newLink });
    },
    async onLinkRemove(link) {
      this.caseStudy.links = this.caseStudy.links.filter(
        (el) => el.link_id !== link.id
      );
    },
  },

  watch: {
    displayedCaseStudies: {
      async handler(newVal) {
        if (newVal) {
          this.caseStudy = this.caseStudyById(
            this.value.relation.params.caseStudyId
          );
        }
      },
      deep: true,
    },
    caseStudy: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (this.$refs.form && this.$refs.form.validate())
          await this.$store.dispatch("CaseStudyStore/UpdateCaseStudy", {
            caseStudy: newVal,
          });
      },
      deep: true,
    },
  },
};
</script>
export class PermissionsHelper {



    /**
     * 
     * @param {Array<Object>|Object} requestedButtons 
     * @param {Object.<string, boolean>} permissions 
     */
    static getActions(requestedButtons, permissions={}) {

        if (!requestedButtons)
            return [];

        const buttons = Array.isArray(requestedButtons) ? requestedButtons : [requestedButtons]

        return buttons.filter(button => permissions[button.permission] || !button.permission)
    }
}
<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 flex-col">
        <HelpFormInputVue
          dark
          v-model="form.name"
          :rules="nameRules"
          label="Case Study Name"
          :helpText="'Provide a name of the case study. Please make sure that it is unique across the system'"
          required
        ></HelpFormInputVue>

        <v-checkbox
          class="my-4"
          :value="form.is_public"
          label="Allow to share links and details (Make it Public)"
          color="success"
          @change="(val) => (form.is_public = val ? true : false)"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col class="pa-0 flex-col">
        <HelpFormInputVue
          dark
          v-model="form.web_url"
          :rules="linkRules"
          label="Web Link"
          :helpText="'Please provide a Link to the product if such exists.'"
          required
        ></HelpFormInputVue>
      </v-col>
    </v-row>

    <HelpFormInputVue
      dark
      :rows="2"
      v-model="form.summary"
      :rules="descriptionRules"
      label="Summary"
      :helpText="'Please provide a summary in a few sentences to describe case study. This summary will be used as a help text.'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.brief"
      :rules="descriptionRules"
      label="Brief"
      :helpText="'Please provide a brief of this case study to make it more recognizable'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.what_has_been_done"
      label="What has been done"
      :helpText="'Please provide an information on what has been done during the case study execution'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 flex-col-300">
        <HelpFormInputVue
          dark
          :rows="4"
          v-model="form.challenges"
          label="Challenges"
          :helpText="'Please provide an information on what has been done during the case study execution'"
          :textarea="true"
          required
        ></HelpFormInputVue>
      </v-col>
      <v-col class="pa-0 flex-col-300">
        <HelpFormInputVue
          dark
          :rows="4"
          v-model="form.solution"
          label="Solution"
          :helpText="'Please provide an information on what has been done during the case study execution'"
          :textarea="true"
          required
        ></HelpFormInputVue>
      </v-col>
    </v-row>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.marketing_description"
      label="Marketing Description"
      :helpText="'This description should help non-technical employees and peoples understand what this case study means. This description may be used on the website or during document/presentations generation'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <FormSection
      right
      underline
      class="mt-0"
      :label="'Business Specifics'"
      :icon="'mdi-domain'"
      :editable="false"
    ></FormSection>

    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 flex-col-300">
        <!-- Business Type -->
        <SelectorBusinessTypes
          dark
          :icon="''"
          :label="'Business Type'"
          :helpText="'Please select the business type to adjust ADAAS suitability model. It will help to manage services in the right way.'"
          required
          return-object
          v-model="form.businessType"
          autoload
        >
        </SelectorBusinessTypes>
      </v-col>
      <v-col class="pa-0 flex-col-300">
        <!-- Business Domains -->
        <SelectorBusinessDomains
          dark
          :icon="''"
          :label="'Business Domains'"
          :helpText="'Please select all business domains that relates to this company or potentially may be used.'"
          required
          multiple
          return-object
          v-model="domains"
          autoload
          @change="
            (e) =>
              onSelectorChange('domains', 'business_domain_id', 'domain')(e)
          "
        >
        </SelectorBusinessDomains>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 flex-col-300">
        <!-- Services -->

        <FormSection
          class="mt-0 pa-2"
          underline
          right
          :label="'Services'"
          :icon="'mdi-briefcase'"
          :actions="serviceActions"
          :editable="!!serviceActions.length"
          @action="(action) => $emit('action', action)"
        ></FormSection>
        <SelectorServices
          dark
          autoload
          label="Services"
          helpText="A set of services associated with this case study"
          return-object
          v-model="services"
          multiple
          @change="
            (e) => onSelectorChange('services', 'service_id', 'service')(e)
          "
        ></SelectorServices>

        <!-- Deliverables -->

        <FormSection
          class="mt-0 pa-2"
          underline
          right
          :label="'Deliverables'"
          :icon="'mdi-file-document-multiple-outline'"
          :actions="deliverableActions"
          :editable="!!deliverableActions.length"
          @action="(action) => $emit('action', action)"
        ></FormSection>

        <SelectorDeliverables
          dark
          autoload
          label="Deliverables"
          helpText="A set of deliverables associated with this case study"
          return-object
          v-model="deliverables"
          multiple
          @change="
            (e) =>
              onSelectorChange(
                'deliverables',
                'deliverable_id',
                'deliverable'
              )(e)
          "
        >
        </SelectorDeliverables>
      </v-col>
      <v-col class="pa-0 flex-col-300">
        <!-- Tags -->
        <FormSection
          class="mt-0 pa-2"
          underline
          right
          :label="'Tags'"
          :icon="'mdi-tag-multiple-outline'"
          :actions="tagActions"
          :editable="!!tagActions.length"
          @action="(action) => $emit('action', action)"
        ></FormSection>

        <SelectorKnowledgeBaseTagsVue
          dark
          autoload
          label="Tags"
          helpText="You can specify a set of tags to make this case study more recognizable"
          return-object
          v-model="tags"
          multiple
          @change="(e) => onSelectorChange('tags', 'tag_id', 'tag')(e)"
        >
        </SelectorKnowledgeBaseTagsVue>

        <!-- Useful Links -->

        <FormSection
          right
          underline
          class="mt-0 pa-2"
          :label="'Useful Links'"
          :icon="'mdi-link-variant'"
          :editable="false"
        ></FormSection>

        <HelpFormInputVue
          dark
          class="mt-4"
          outlined
          v-model="newLink"
          :rules="linkRules"
          label="New Link"
          :helpText="'This text will be used to adjust a search in the right way. E.g. this data will be used in Leads-Analyzer'"
          required
          @onEnter="onNewLinkCreate"
        ></HelpFormInputVue>
        <LinksList
          v-if="form.links && form.links.length > 0"
          :value="form.links"
          @onLinkRemove="onLinkRemove(link)"
        ></LinksList>
      </v-col>
    </v-row>

    <FormSection
      right
      underline
      class="mt-0 pa-2"
      :label="'Other Specifics'"
      :icon="'mdi-link-variant'"
      :editable="false"
    ></FormSection>
    <v-row>
      <v-col class="pa-0 flex-col-300">
        <SelectorGlossaryTerm
          dark
          autoload
          label="Terms"
          helpText="Please provide a set of terms that are applicable for this case study"
          return-object
          v-model="terms"
          multiple
          @change="(e) => onSelectorChange('terms', 'term_id', 'term')(e)"
        ></SelectorGlossaryTerm>
      </v-col>
      <v-col class="pa-0 flex-col-300">
        <SelectorExperienceNotes
          dark
          autoload
          label="Experience Records"
          helpText="Please provide experience records that are valid for this case study"
          return-object
          v-model="notes"
          multiple
          @change="(e) => onSelectorChange('notes', 'note_id', 'note')(e)"
        ></SelectorExperienceNotes>
      </v-col>
    </v-row>
  </v-form>
</template>
      
      
<script>
import SelectorKnowledgeBaseTagsVue from "../../../../../atoms/common/autocompletes/SelectorKnowledgeBaseTags.vue";
import FormSection from "../../../../../atoms/common/FormSection.vue";
import HelpFormInputVue from "../../../../../atoms/common/inputs/HelpFormInput.vue";
import _ from "lodash";
import LinksList from "../../../../knowledgeBase/links/LinksList.vue";
import SelectorServices from "../../../../../atoms/common/autocompletes/SelectorServices.vue";
import SelectorDeliverables from "../../../../../atoms/common/autocompletes/SelectorDeliverables.vue";
import { PermissionsHelper } from "../../../../../../helpers/permissions.helper";
import {
  DELIVERABLE_BUTTONS,
  SERVICE_BUTTONS,
} from "../../../../../../../views/src/apps/service-management/constants/actions.gh";
import { TAGS_BUTTONS } from "../../../../../../../views/src/apps/knowledge-base/constants/actions.gh";
import SelectorBusinessDomains from "../../../../../atoms/common/autocompletes/SelectorBusinessDomains.vue";
import SelectorBusinessTypes from "../../../../../atoms/common/autocompletes/SelectorBusinessTypes.vue";
import SelectorGlossaryTerm from "../../../../../atoms/common/autocompletes/SelectorGlossaryTerm.vue";
import SelectorExperienceNotes from "../../../../../atoms/common/autocompletes/SelectorExperienceNotes.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    onLinkCreate: {
      type: Function,
      default: async () => {},
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tagActions: function () {
      return PermissionsHelper.getActions(
        [TAGS_BUTTONS.CREATE_NEW],
        this.permissions
      );
    },
    deliverableActions: function () {
      return PermissionsHelper.getActions(
        [DELIVERABLE_BUTTONS.LIST, DELIVERABLE_BUTTONS.CREATE_NEW],
        this.permissions
      );
    },
    serviceActions: function () {
      return PermissionsHelper.getActions(
        [SERVICE_BUTTONS.LIST, SERVICE_BUTTONS.CREATE_NEW],
        this.permissions
      );
    },
  },
  data() {
    const { tags, services, deliverables, domains, terms, notes } =
      this.prepareLists(this.value);

    return {
      // Form
      pValid: this.valid,
      form: this.value,

      tags,
      services,
      deliverables,
      domains,
      terms,
      notes,
      newLink: "",

      // ======rules
      nameRules: [(v) => !!v || "Term Name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],

      linkRules: [
        (v) =>
          !v || /(https?:\/\/[^\s]+)/.test(v) || "Link should be a proper link",
      ],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorKnowledgeBaseTagsVue,
    FormSection,
    LinksList,
    SelectorServices,
    SelectorDeliverables,
    SelectorBusinessTypes,
    SelectorBusinessDomains,
    SelectorGlossaryTerm,
    SelectorExperienceNotes,
  },
  methods: {
    prepareLists(value) {
      let tags = [];
      let services = [];
      let deliverables = [];
      let domains = [];
      let terms = [];
      let notes = [];

      if (value.tags && value.tags.length) tags = value.tags.map((t) => t.tag);

      if (value.services && value.services.length)
        services = value.services.map((s) => s.service);

      if (value.deliverables && value.deliverables.length)
        deliverables = value.deliverables.map((t) => t.deliverable);

      if (value.domains && value.domains.length)
        domains = value.domains.map((t) => t.domain);

      if (value.terms && value.terms.length)
        terms = value.terms.map((t) => t.term);

      if (value.notes && value.notes.length)
        notes = value.notes.map((t) => t.note);

      return { tags, services, deliverables, domains, terms, notes };
    },

    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onNewLinkCreate() {
      this.$emit("onLinkCreate", this.newLink);
    },
    onLinkRemove(link) {
      this.$emit("onLinkRemove", link);
    },
    onSelectorChange(arrayName, keyName, objectName) {
      return (newVal) => {
        console.log("ON CHANGE: ", arrayName);
        this.form[arrayName] = newVal.map((item, i, arr) => {
          const existed = _.find(arr, (t) => t[keyName] === item.id);

          if (existed) return existed;
          else {
            const out = {};

            out[objectName] = item;
            return out;
          }
        });
      };
    },
  },
  watch: {
    value: {
      handler(newVal) {
        console.log("Val Updated");
        const lists = this.prepareLists(newVal);

        for (const key in lists) {
          this[key] = lists[key];
        }

        this.newLink = "";

        this.form = newVal;
      },
      deep: true,
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
      
      
<style lang="scss" scoped>
.term-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>
import {
    store
} from "../../../../../main"
import {
    DELIVERABLE_PERMISSIONS,
    SERVICE_PERMISSIONS
} from "./permissions.gh"



export const SERVICE_BUTTONS = {
    LIST: {
        name: "Services",
        description: "Will open Services List",
        icon: "mdi-briefcase",
        color: "#23842a",
        on: "onServicesList",
        permission: SERVICE_PERMISSIONS.ALLOW_SEE_SERVICE_LIST,
        handler: (props) => store.dispatch('ServiceStore/openServiceList', props)
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will create new Service",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onServiceCreate",
        permission: SERVICE_PERMISSIONS.ALLOW_CREATE_SERVICE,
        handler: (props) => store.dispatch('ServiceStore/openServiceCreation', props)
    },
    SAVE: {
        name: "Save",
        description: "Will save new Service",
        icon: "mdi-check",
        color: "#23842a",
        on: "onServiceSave",
        permission: SERVICE_PERMISSIONS.ALLOW_CREATE_SERVICE
    },
    SETTINGS_EDITOR_MAIN: {
        name: "Settings",
        description: "Will open Service Settings",
        icon: "mdi-cog",
        color: "#23842a",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_SETTINGS,
        on: "onServiceSettingsEditor",
        handler: (props) => store.dispatch('ServiceStore/openServiceSettingsEditor', props)
    },
    HEADER_EDITOR_MAIN: {
        name: "Header",
        description: "Will open Header Editor",
        icon: "mdi-alpha-h-box",
        color: "#23842a",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_HEADER,
        on: "onServiceHeaderEditor",
        handler: (props) => store.dispatch('ServiceStore/openServiceHeaderEditor', props)
    },
    OVERVIEW_EDITOR_MAIN: {
        name: "Overview",
        description: "Will open Overview Editor",
        icon: "mdi-alpha-o-box",
        color: "#23842a",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_OVERVIEW,
        on: "onServiceOverviewEditor",
        handler: (props) => store.dispatch('ServiceStore/openServiceOverviewEditor', props)
    },
    FREE_SECTION_EDITOR_MAIN: {
        name: "Free Sections",
        description: "Will open Free Sections Editor",
        icon: "mdi-alpha-f-box",
        color: "#23842a",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_FREE_SECTIONS,
        on: "onServiceFreeSectionEditor",
        handler: (props) => store.dispatch('ServiceStore/openServiceFreeSectionEditor', props)
    },
    USE_CASES_EDITOR_MAIN: {
        name: "Use Cases",
        description: "Will open Use Cases Editor",
        icon: "mdi-alpha-u-box",
        color: "#23842a",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_USE_CASES,
        on: "onServiceUseCasesEditor",
        handler: (props) => store.dispatch('ServiceStore/openServiceUseCaseEditor', props)
    },
    DELIVERABLES_EDITOR_MAIN: {
        name: "Deliverables",
        description: "Will open Deliverables Editor",
        icon: "mdi-alpha-d-box",
        color: "#23842a",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_DELIVERABLES,
        on: "onServiceDeliverablesEditor",
        handler: (props) => store.dispatch('ServiceStore/openServiceDeliverablesEditor', props)
    },
    PLANS_EDITOR_MAIN: {
        name: "Plans",
        description: "Will open Plans Editor",
        icon: "mdi-alpha-p-box",
        color: "#23842a",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_PLANS,
        on: "onServicePlansEditor",
        handler: (props) => store.dispatch('ServiceStore/openServicePlansEditor', props)
    },
    DOWNLOAD_PDF: {
        name: "DownloadPdf",
        description: "Will open Plans Editor",
        icon: "mdi-download-box-outline",
        color: "#23842a",
        // permission: SERVICE_PERMISSIONS.ALLOW_SEE_SERVICE_LIST,
        on: "onServicePdfDownload",
    },



    // =================== EDITORS ===================
    HEADER_EDITOR: {
        name: "Edit",
        description: "Will open Header Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onServiceHeaderEditor",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_HEADER,
        handler: (props) => store.dispatch('ServiceStore/openServiceHeaderEditor', props)

    },
    OVERVIEW_EDITOR: {
        name: "Edit",
        description: "Will open Overview Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onServiceOverviewEditor",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_OVERVIEW,
        handler: (props) => store.dispatch('ServiceStore/openServiceOverviewEditor', props)
    },
    FREE_SECTION_EDITOR: {
        name: "Edit",
        description: "Will open Free Sections Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_FREE_SECTIONS,
        on: "onServiceFreeSectionEditor",
        handler: (props) => store.dispatch('ServiceStore/openServiceFreeSectionEditor', props)
    },
    USE_CASES_EDITOR: {
        name: "Edit",
        description: "Will open Use Cases Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_USE_CASES,
        on: "onServiceUseCasesEditor",
        handler: (props) => store.dispatch('ServiceStore/openServiceUseCaseEditor', props)
    },
    DELIVERABLES_EDITOR: {
        name: "Edit",
        description: "Will open Deliverables Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_DELIVERABLES,
        on: "onServiceDeliverablesEditor",
        handler: (props) => store.dispatch('ServiceStore/openServiceDeliverablesEditor', props)
    },
    PLANS_EDITOR: {
        name: "Edit",
        description: "Will open Plans Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_PLANS,
        on: "onServicePlansEditor",
        handler: (props) => store.dispatch('ServiceStore/openServicePlansEditor', props)
    },
    FREE_SECTION_CREATE_NEW: {
        name: "Create",
        description: "Will create new Service Section",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onServiceFreeSectionCreate",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_FREE_SECTIONS,
        handler: (props) => store.dispatch('ServiceStore/onFreeSectionCreate', props)
    },
    FREE_SECTION_REMOVE: {
        name: "Remove",
        description: "Will remove this Section",
        icon: "mdi-delete",
        color: "#B32251",
        on: "onServiceFreeSectionRemove",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_FREE_SECTIONS,
        handler: (props) => store.dispatch('ServiceStore/onFreeSectionRemove', props)

    },
    USE_CASE_CREATE_NEW: {
        name: "Create",
        description: "Will create new Service Section",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onServicePlanCreate",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_USE_CASES,
        handler: (props) => store.dispatch('ServiceStore/onUseCaseCreate', props)

    },
    PLAN_CREATE_NEW: {
        name: "Create",
        description: "Will create new Service Section",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onServicePlanCreate",
        permission: SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_PLANS,
        handler: (props) => store.dispatch('ServiceStore/onPlanCreate', props)

    },
}


export const DELIVERABLE_BUTTONS = {
    LIST: {
        name: "Deliverables",
        description: "Will open Deliverables List",
        icon: "mdi-file-document-multiple-outline",
        color: "#23842a",
        on: "onDeliverablesList",
        permission: DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_LIST,
        handler: (props) => store.dispatch('DeliverableStore/openDeliverableList', props)
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will create new Deliverable",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onDeliverableCreate",
        permission: DELIVERABLE_PERMISSIONS.ALLOW_CREATE_DELIVERABLE,
        handler: (props) => store.dispatch('DeliverableStore/openDeliverableCreation', props)
    },
    SAVE: {
        name: "Save",
        description: "Will save new Deliverable",
        icon: "mdi-check",
        color: "#23842a",
        on: "onDeliverableSave",
        permission: DELIVERABLE_PERMISSIONS.ALLOW_CREATE_DELIVERABLE
    },
    FILES_EDITOR_MAIN: {
        name: "Files",
        description: "Will open Examples Editor",
        icon: "mdi-file-document-multiple",
        color: "#23842a",
        on: "onDeliverableFilesEdit",
        permission: DELIVERABLE_PERMISSIONS.ALLOW_EDIT_DELIVERABLE,
        handler: (props) => store.dispatch('DeliverableStore/openDeliverableFilesEditor', props)
    },
    GENERAL_EDITOR_MAIN: {
        name: "Settings",
        description: "Will open General Settings",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onDeliverableGeneralEdit",
        permission: DELIVERABLE_PERMISSIONS.ALLOW_EDIT_DELIVERABLE,
        handler: (props) => store.dispatch('DeliverableStore/openDeliverableGeneralEditor', props)
    },
    // =================== EDITORS ===================
    GENERAL_EDITOR: {
        name: "Settings",
        description: "Will open General configurations",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onDeliverableGeneralEdit",
        permission: DELIVERABLE_PERMISSIONS.ALLOW_EDIT_DELIVERABLE,
        handler: (props) => store.dispatch('DeliverableStore/openDeliverableGeneralEditor', props)
    },
    FILES_EDITOR: {
        name: "Edit",
        description: "Will open Examples Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onDeliverableFilesEdit",
        permission: DELIVERABLE_PERMISSIONS.ALLOW_EDIT_DELIVERABLE,
        handler: (props) => store.dispatch('DeliverableStore/openDeliverableFilesEditor', props)

    },
}
<template>
  <v-card class="transparent elevation-0">
    <v-slide-y-transition class="py-0 px-0" group tag="ul">
      <template v-for="(link, i) in value">
        <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>

        <v-list-item class="pa-2" :key="link.link.link">
          <v-list-item-action>
            <a :href="link.link.link" target="blank">
              <v-icon> mdi-link-variant </v-icon>
            </a>
          </v-list-item-action>

          <v-list-item-title>
            {{ link.link.link }}
          </v-list-item-title>
          <v-scroll-x-transition v-if="removable">
            <v-icon @click="() => $emit('onLinkRemove', link)" color="primary">
              mdi-delete
            </v-icon>
          </v-scroll-x-transition>
        </v-list-item>
      </template>
    </v-slide-y-transition>
  </v-card>
</template>
      
      
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    removable: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({}),
  computed: {},
  async created() {},
  methods: {},
};
</script>
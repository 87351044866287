import {
    store
} from "../../../../../main"
import {
    CASE_STUDY_PERMISSIONS,
    EXPERIENCE_NOTE_PERMISSIONS,
    QUESTION_PERMISSIONS,
    GLOSSARY_PERMISSIONS,
    TAGS_PERMISSIONS,
    BUSINESS_DOMAIN_PERMISSIONS
} from "./permissions.gh"



export const TAGS_BUTTONS = {
    CREATE_NEW: {
        name: "Create",
        description: "Will create new Tag",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onTagCreate",
        permission: TAGS_PERMISSIONS.ALLOW_CREATE_TAG,
        handler: (props) => store.dispatch('TagsStore/openTagCreation', props)
    },
    SAVE: {
        name: "Save",
        description: "Will save new Tag",
        icon: "mdi-check",
        color: "#23842a",
        on: "onTagSave",
        permission: CASE_STUDY_PERMISSIONS.ALLOW_CREATE_CASE_STUDY
    },
}


export const CASE_STUDY_BUTTONS = {
    LIST: {
        name: "Case Studies",
        description: "Will open Case Study List",
        icon: "mdi-store-outline",
        color: "#23842a",
        on: "onCaseStudyList",
        permission: CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_LIST,
        handler: (props) => store.dispatch('CaseStudyStore/openCaseStudyList', props)
    },
    SAVE: {
        name: "Save",
        description: "Will save new Case Study",
        icon: "mdi-check",
        color: "#23842a",
        on: "onCaseStudySave",
        permission: CASE_STUDY_PERMISSIONS.ALLOW_CREATE_CASE_STUDY
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will create new Case Study",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onCaseStudyCreate",
        permission: CASE_STUDY_PERMISSIONS.ALLOW_CREATE_CASE_STUDY,
        handler: (props) => store.dispatch('CaseStudyStore/openCaseStudyCreation', props)
    },
    SETTINGS_EDITOR_MAIN: {
        name: "Settings",
        description: "Will open Case Study Settings",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onCaseStudySettings",
        permission: CASE_STUDY_PERMISSIONS.ALLOW_EDIT_CASE_STUDY_SETTINGS,
        handler: (props) => store.dispatch('CaseStudyStore/openCaseStudySettingsEditor', props)
    },
    GENERAL_EDITOR_MAIN: {
        name: "Edit",
        description: "Will open Case Study Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onCaseStudyGeneralEdit",
        permission: CASE_STUDY_PERMISSIONS.ALLOW_EDIT_CASE_STUDY,
        handler: (props) => store.dispatch('CaseStudyStore/openCaseStudyGeneralEditor', props)

    },
    FILES_EDITOR_MAIN: {
        name: "Files",
        description: "Will open Files Editor",
        icon: "mdi-file-document-multiple",
        color: "#23842a",
        on: "onCaseStudyFilesEdit",
        permission: CASE_STUDY_PERMISSIONS.ALLOW_EDIT_CASE_STUDY_FILES,
        handler: (props) => store.dispatch('CaseStudyStore/openCaseStudyFilesEditor', props)
    },
    // =================== EDITORS ===================
    GENERAL_EDITOR: {
        name: "Edit",
        description: "Will open Case Study General Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onCaseStudyGeneralEdit",
        permission: CASE_STUDY_PERMISSIONS.ALLOW_EDIT_CASE_STUDY,
        handler: (props) => store.dispatch('CaseStudyStore/openCaseStudyGeneralEditor', props)

    },
    FILES_EDITOR: {
        name: "Edit",
        description: "Will open Files Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onCaseStudyFilesEdit",
        permission: CASE_STUDY_PERMISSIONS.ALLOW_EDIT_CASE_STUDY_FILES,
        handler: (props) => store.dispatch('CaseStudyStore/openCaseStudyFilesEditor', props)
    },
}







export const QUESTION_BUTTONS = {
    LIST: {
        name: "Questions",
        description: "Will open Questions List",
        icon: "mdi-help-box",
        color: "#23842a",
        on: "onQuestionList",
        permission: QUESTION_PERMISSIONS.ALLOW_SEE_QUESTION_LIST,
        handler: (props) => store.dispatch('QuestionStore/openQuestionList', props)
    },
    SAVE: {
        name: "Save",
        description: "Will save new Question",
        icon: "mdi-check",
        color: "#23842a",
        on: "onQuestionSave",
        permission: QUESTION_PERMISSIONS.ALLOW_CREATE_QUESTION
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will open Question creation",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onQuestionCreate",
        permission: QUESTION_PERMISSIONS.ALLOW_CREATE_QUESTION,
        handler: (props) => store.dispatch('QuestionStore/openQuestionCreation', props)
    },
    SETTINGS_EDITOR_MAIN: {
        name: "Settings",
        description: "Will open Question Settings",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onQuestionSettings",
        permission: QUESTION_PERMISSIONS.ALLOW_EDIT_QUESTION_SETTINGS,
        handler: (props) => store.dispatch('QuestionStore/openQuestionSettingsEditor', props)
    },
    GENERAL_EDITOR_MAIN: {
        name: "Edit",
        description: "Will open Question Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onQuestionGeneralEdit",
        permission: QUESTION_PERMISSIONS.ALLOW_EDIT_QUESTION,
        handler: (props) => store.dispatch('QuestionStore/openQuestionGeneralEditor', props)
    },
    // =================== EDITORS ===================
    GENERAL_EDITOR: {
        name: "Edit",
        description: "Will open Question General Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onQuestionGeneralEdit",
        permission: QUESTION_PERMISSIONS.ALLOW_EDIT_QUESTION,
        handler: (props) => store.dispatch('QuestionStore/openQuestionGeneralEditor', props)
    }
}

// Glossary
export const EXPERIENCE_NOTE_BUTTONS = {
    LIST: {
        name: "Experience Records",
        description: "Will open Experience Records List",
        icon: "mdi-help-box",
        color: "#23842a",
        on: "onExperienceNoteList",
        permission: EXPERIENCE_NOTE_PERMISSIONS.ALLOW_SEE_EXPERIENCE_NOTE_LIST,
        handler: (props) => store.dispatch('ExperienceNotesStore/openExperienceNoteList', props)
    },
    SAVE: {
        name: "Save",
        description: "Will save new Experience Note",
        icon: "mdi-check",
        color: "#23842a",
        on: "onExperienceNoteSave",
        permission: EXPERIENCE_NOTE_PERMISSIONS.ALLOW_CREATE_EXPERIENCE_NOTE
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will open Experience Note creation",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onExperienceNoteCreate",
        permission: EXPERIENCE_NOTE_PERMISSIONS.ALLOW_CREATE_EXPERIENCE_NOTE,
        handler: (props) => store.dispatch('ExperienceNotesStore/openExperienceNoteCreation', props)
    },
    SETTINGS_EDITOR_MAIN: {
        name: "Settings",
        description: "Will open Experience Note Settings",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onExperienceNoteSettings",
        permission: EXPERIENCE_NOTE_PERMISSIONS.ALLOW_EDIT_EXPERIENCE_NOTE_SETTINGS,
        handler: (props) => store.dispatch('ExperienceNotesStore/openExperienceNoteSettingsEditor', props)
    },
    GENERAL_EDITOR_MAIN: {
        name: "Edit",
        description: "Will open Experience Note Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onExperienceNoteGeneralEdit",
        permission: EXPERIENCE_NOTE_PERMISSIONS.ALLOW_EDIT_EXPERIENCE_NOTE,
        handler: (props) => store.dispatch('ExperienceNotesStore/openExperienceNoteGeneralEditor', props)
    },
    // =================== EDITORS ===================
    GENERAL_EDITOR: {
        name: "Edit",
        description: "Will open Experience Note General Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onExperienceNoteGeneralEdit",
        permission: EXPERIENCE_NOTE_PERMISSIONS.ALLOW_EDIT_EXPERIENCE_NOTE,
        handler: (props) => store.dispatch('ExperienceNotesStore/openExperienceNoteGeneralEditor', props)
    }
}






export const GLOSSARY_BUTTONS = {
    LIST: {
        name: "Glossary",
        description: "Will open Glossary",
        icon: "mdi-order-alphabetical-ascending",
        color: "#23842a",
        on: "onTermList",
        permission: GLOSSARY_PERMISSIONS.ALLOW_SEE_GLOSSARY_LIST,
        handler: (props) => store.dispatch("GlossaryStore/openTermList", props)

    },
    CREATE_NEW: {
        name: "Create",
        description: "Will create new Term",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onGlossaryTermCreate",
        permission: GLOSSARY_PERMISSIONS.ALLOW_CREATE_GLOSSARY,
        handler: (props) => store.dispatch("GlossaryStore/openTermCreation", props)
    },
    SAVE: {
        name: "Save",
        description: "Will save new Glossary Term",
        icon: "mdi-check",
        color: "#23842a",
        on: "onGlossaryTermSave",
        permission: GLOSSARY_PERMISSIONS.ALLOW_CREATE_GLOSSARY
    },
    GENERAL_EDITOR_MAIN: {
        name: "Settings",
        description: "Will Term General Editor",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onGlossaryTermGeneralEdit",
        permission: GLOSSARY_PERMISSIONS.ALLOW_EDIT_GLOSSARY,
        handler: (props) => store.dispatch("GlossaryStore/openTermGeneralEditor", props)
    },
    // =================== EDITORS ===================
    GENERAL_EDITOR: {
        name: "Edit",
        description: "Will open Term General Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onGlossaryTermGeneralEdit",
        permission: GLOSSARY_PERMISSIONS.ALLOW_EDIT_GLOSSARY,
        handler: (props) => store.dispatch("GlossaryStore/openTermGeneralEditor", props)
    },
}




export const BUSINESS_DOMAIN_BUTTONS = {
    LIST: {
        name: "Business Domains",
        description: "Will open Business Domain List",
        icon: "mdi-help-box",
        color: "#23842a",
        on: "onBusinessDomainList",
        permission: BUSINESS_DOMAIN_PERMISSIONS.ALLOW_SEE_BUSINESS_DOMAIN_LIST,
        handler: (props) => store.dispatch('BusinessDomainStore/openBusinessDomainList', props)
    },
    SAVE: {
        name: "Save",
        description: "Will save new Business Domain",
        icon: "mdi-check",
        color: "#23842a",
        on: "onBusinessDomainSave",
        permission: BUSINESS_DOMAIN_PERMISSIONS.ALLOW_CREATE_BUSINESS_DOMAIN
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will open Business Domain creation",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onBusinessDomainCreate",
        permission: BUSINESS_DOMAIN_PERMISSIONS.ALLOW_CREATE_BUSINESS_DOMAIN,
        handler: (props) => store.dispatch('BusinessDomainStore/openBusinessDomainCreation', props)
    },
    SETTINGS_EDITOR_MAIN: {
        name: "Settings",
        description: "Will open Business Domain Settings",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onBusinessDomainSettings",
        permission: BUSINESS_DOMAIN_PERMISSIONS.ALLOW_EDIT_BUSINESS_DOMAIN_SETTINGS,
        handler: (props) => store.dispatch('BusinessDomainStore/openBusinessDomainSettingsEditor', props)
    },
    GENERAL_EDITOR_MAIN: {
        name: "Edit",
        description: "Will open Business Domain Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onBusinessDomainGeneralEdit",
        permission: BUSINESS_DOMAIN_PERMISSIONS.ALLOW_EDIT_BUSINESS_DOMAIN,
        handler: (props) => store.dispatch('BusinessDomainStore/openBusinessDomainGeneralEditor', props)
    },
    // =================== EDITORS ===================
    GENERAL_EDITOR: {
        name: "Edit",
        description: "Will open Business Domain General Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onBusinessDomainGeneralEdit",
        permission: BUSINESS_DOMAIN_PERMISSIONS.ALLOW_EDIT_BUSINESS_DOMAIN,
        handler: (props) => store.dispatch('BusinessDomainStore/openBusinessDomainGeneralEditor', props)
    }
}